@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}

.meeting-page-container-meeting-on {
  overflow: hidden;
}

.meeting-video-conference-loading-spinner {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 37.5%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
