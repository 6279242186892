.meeting-sidebar {
  text-align: center;
  height: 100vh;
  width: 100%;
  background-color: #0052cc;
  margin: 0 4px;
}

.meeting-sidebar-font-white * {
  color: white;
}

.meeting-sidebar-header {
  border: black;
  padding: 1vh;
  height: 20%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.meeting-sidebar-content {
  padding: 0vh 1vh 2vh 0vh;
  height: 68%;
  overflow-x: hidden;
  overflow-y: auto;
}

.meeting-sidebar-subcontent {
  margin: 3vh;
}

.meeting-sidebar-footer {
  margin: 0vh;
  height: 12%;
  overflow-x: hidden;
  overflow-y: auto;
}
