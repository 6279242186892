.agenda-item-container {
  margin: 1.5vh 0vh 1vh 0vh;
  width: 100%;
}

.agenda-item-name {
  text-align: justify;
  font-weight: bold;
}

.agenda-item-name-centered {
  text-align: center;
  font-weight: bold;
}

.agenda-item-checkbox-blue * {
  color: #0052cc !important;
}
