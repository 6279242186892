.new-meeting-modal-char-limit {
  padding: 1vh;
  opacity: 0.3;
}

.new-meeting-modal-char-limit.reached {
  color: red;
  opacity: 1;
}

.new-meeting-modal-title {
  font-size: xx-large;
  padding: 30px 0px 0px 0px;
  align-self: center;
  text-align: center;
}

.new-meeting-modal-header {
  margin: 25px 30px 0 30px;
}

.new-meeting-modal-duration-hint {
  margin-top: 3px;
  font-style: oblique;
}

.new-meeting-modal-meeting-subsection {
  margin: 35px 50px 0px 50px;
  text-align: center;
}

.new-meeting-modal-meeting-subsection-header {
  margin: 10px 50px 0px 50px;
  text-align: center;
}

.new-meeting-modal-body {
  text-align: center;
  margin-top: 0px;
}

.new-meeting-modal-footer {
  text-align: center;
}

.new-meeting-modal-name {
  text-align: center;
}

.new-meeting-modal-button-close {
  font-size: large;
}

.new-meeting-modal-button-copy-meeting-link {
  font-size: large;
  text-align: end;
}

.new-meeting-modal-note-meeting-link {
  font-size: small;
  text-align: start;
}
